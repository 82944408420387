<template>
  <div class="pb-1 preview-actions">
    <div class="d-flex justify-content-end flex-wrap mr-4">
      <!--  Preview Contest Button    -->
      <div
          class="d-flex justify-content-end"
          v-if="pageType === 'PreviewContest'">
        <ClNavButton
          :loading="loading"
          :label="$t('buttons.goBack')"
          @handler="goToCompetitionPreview"
        />
<!--        <CButton-->
<!--          :disabled="loading"-->
<!--          color="dark"-->
<!--          class="ml-1 mb-2 common-buttons zq&#45;&#45;custom-button"-->
<!--          variant="outline"-->
<!--          @click="handleGoToLeaderboard"-->
<!--        >-->
<!--          {{ $t('buttons.leaderboard') }}-->
<!--        </CButton>-->
      </div>
      <!--   Actions Dropdown   -->
      <CDropdown
        inNav
        class="c-header-nav-items custom-dropbox"
        placement="bottom-end"
        add-menu-classes="pt-0"
      >
        <template #toggler class="justify-content-end">
          <CButton
            :disabled="loading"
            class="ml-1 zq--custom-button"
            color="dark"
            variant="outline"
          >
            {{ $t('buttons.action') }}
            <i
                v-if="theme === 'main'"
                class="fa fa-angle-down font-weight-bold"></i>
            <i v-else class="fa fa-caret-down"></i>
          </CButton>
        </template>
        <CDropdownItem class="p-0" v-for="item in dropDownActions" :key="item.title">
          <CButton
            pill
            :disabled="loading"
            color="dark"
            class="action-custom-button"
            variant="ghost"
            @click="actionEvent(item.handler)"
          >
            {{ item.title }}
          </CButton>
        </CDropdownItem>
      </CDropdown>
    </div>

    <!-- Create button   -->
    <div v-if="pageType === 'PreviewCompetition'" class="d-flex  justify-content-end">
      <ClResponsiveButton :label="$t('buttons.createContest')" @handler="handleCreateContest"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ClResponsiveButton from '@/shared/components/formComponents/ClResponsiveButton';
import ClNavButton from '@/shared/components/formComponents/ClNavButton';

export default {
  components: {
    ClResponsiveButton,
    ClNavButton
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    pageType: {
      type: String,
      default: 'PreviewCompetition'
    },
    dropDownActions: Array,
    // goToLeaderboard: Function,
  },
  computed:{
    ...mapGetters('theme', ['theme']),
    ...mapGetters('competitions', ['competition']),
  },
  methods: {
    goToCompetitionPreview() {
      this.$emit('goBack');
    },
    // handleGoToLeaderboard() {
    //   this.goToLeaderboard()
    // },
    actionEvent(action) {
      this.$emit('choice', action);
    },
    handleCreateContest() {
      this.$router.push({
        name: 'CreateContest',
        params: {
          id: this.$route.params.id
        }
      })
    }
  }
}
</script>

<style lang="scss">
.preview-actions {
  display: flex;
  justify-content: flex-end;

  .custom-dropbox {
    list-style: none;

    .dropdown-menu {
      min-width: 90px;
    }
  }

  .action-create-button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 160px;
  }
}

</style>