<template>
  <div class="preview">
    <!--  HEADER  -->
    <div class="zq-page-title-wrapper d-flex">
      <h3 class="zq-page-title">{{ texts.previewPage.title }}</h3>
      <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
    </div>
    <CTabs class="zq--tabs" @update:activeTab="updateActiveTab" ref="achievementTabs">
      <CTab :title="texts.previewPage.contestTab">
        <ContestDetail v-if="isContest" @setContestStatus="setContestStatus"/>
      </CTab>
      <CTab :title="texts.previewPage.entrantsTab">
        <EntrantsDetails v-if="isEntrants" :entityId="$route.params.contestId"/>
      </CTab>
      <CTab :title="texts.previewPage.leaderboardTab">
        <ContestLeaderboard v-if="contestId && isLeaderboard" :contestId="contestId"/>
      </CTab>
      <CTab :title="texts.previewPage.awardsTab" v-if="contestStatus === 'Finalised'">
        <AwardsDetails v-if="isAwards" :entityType="'Contest'" />
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import ContestDetail from './ContestDetail';
import EntrantsDetails from '@/shared/components/supportModels/entrants/EntityEntrants';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import ContestLeaderboard from '@/shared/components/supportModels/leaderboard/ContestLeaderboard';
import { contests } from '@/config/descriptions/contests.json';
import { contestsTexts } from "@/config/pageTexts/contests.json";
import AwardsDetails from '@/views/ziqni/achievements/AwardsDetails.vue';

export default {
  name: 'PreviewContest',
  data() {
    return {
      descriptions: {
        ...contests.list.preview
      },
      texts: {
        ...contestsTexts
      },
      isContest: false,
      isEntrants: false,
      isLeaderboard: false,
      isAwards: false,
      tabsMap: {
        0: 'isContest',
        1: 'isEntrants',
        2: 'isLeaderboard',
        3: 'isAwards',
      },
      contestId: null,
      contestStatus: ''
    }
  },
  components: {
    AwardsDetails,
    ContestDetail,
    EntrantsDetails,
    IconWithTooltip,
    ContestLeaderboard,
  },
  mounted() {
    this.isContest = true;
    this.contestId = this.$route.params.contestId;
  },
  methods: {
    updateActiveTab(val) {
      switch (this.tabsMap[val]) {
        case 'isContest':
          this.isContest = true;
          this.isEntrants = false;
          this.isLeaderboard = false;
          this.isAwards = false;
          break;
        case 'isEntrants':
          this.isEntrants = true;
          this.isContest = false;
          this.isLeaderboard = false;
          this.isAwards = false;
          break;
        case 'isLeaderboard':
          this.isLeaderboard = true;
          this.isEntrants = false;
          this.isContest = false;
          this.isAwards = false;
          break;
        case 'isAwards':
          this.isAwards = true;
          this.isLeaderboard = false;
          this.isEntrants = false;
          this.isContest = false;
          break;
      }
    },
    setContestStatus(status) {
      this.contestStatus = status;
    }
  }
}
</script>

<style lang="scss">
.preview {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>