<template>
  <div class="events-detail content">
    <div class="entrants--actions-header pb-2">
      <ClSearch
        placeholder="tableHeader.search"
        @searchUpdate="searchData"
      />
      <CButton
        size="sm"
        class="header-icon-buttons mr-3"
        @click.stop.prevent="handleReload"
      >
        <CIcon name="cil-reload"/>
      </CButton>
    </div>
    <CRow class="mt-1">
      <CCol col="12">
        <CCard class="zq-card-table">
          <CCardBody v-if="ready">
            <CDataTable
              id="dataTableId"
              ref="dataTableRef"
              class="zq--table"
              :class="{ 'is-show-filter': columnFilter }"
              :items="entrantsData"
              :fields="tableFields"
              :column-filter="columnFilter"
              :items-per-page="itemsPerPage"
              :sorter="{ external: true }"
              :responsive="true"
              :border="isDefaultTheme"
              :hover="isDefaultTheme"
              :striped="isDefaultTheme"
              :loading="loading"
              @pagination-change="itemsPerPageSelect"
              @page-change="paginationChange"
              @update:sorter-value="handleSortBy"
              :sorter-value.sync="sorterValue"
            >
              <template #loading>
                <TableLoader />
              </template>
              <template #memberId="{ item }">
                <td>
                  <div class="copy-ceil">
                    <router-link :to="{ name: `PreviewMember`, params: {id: item.memberId} }">
                      {{ item.memberId }}
                    </router-link>
                    <i
                      class="fa fa-copy zq-card-row--copy-icon"
                      @click="handleCopy(item.memberId)"
                    />
                  </div>
                </td>
              </template>
              <template #select-filter>
                <div></div>
              </template>
              <template #actions-filter>
                <div></div>
              </template>
            </CDataTable>
            <TableFooter
              :page="page"
              @updatePagenation="paginationChange"
              :pages="pages"
              :total="totalRecords"
              :itemsPerPage="itemsPerPage"
              @updateItemPerPage="itemsPerPageSelect"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import ClSearch from '@/shared/components/formComponents/ClSearch';
import TableLoader from '@/components/table/Loader';
import TableFooter from '@/components/table/Footer';
import { mapActions, mapGetters } from 'vuex';
import { pageConfig } from '@/config';

export default {
  name: 'EntityEntrants',
  components: {
    ClSearch,
    TableLoader,
    TableFooter,
  },
  data() {
    return {
      entrantsData: [],
      ready: false,
      columnFilter: false,
      isTableScrollbar: false,
      itemsPerPage: pageConfig.itemsPerPage,
      sortBy: [
        {
          queryField: 'timestamp',
          order: 'Desc'
        }
      ],
      page: pageConfig.page,
      tableFields: ['memberId', 'entrantStatus', 'entityId', 'entityType'],
      searchableAllFields: ['memberId'],
      query: null,
    };
  },
  props: {
    entityId: String,
    entityType: {
      type: String,
      default: null
    },
  },
  computed: {
    ...mapGetters('entrants', [
      'entrants',
      'fields',
      'pages',
      'loading',
      'resultCount',
      'totalRecords',
      'originalFields',
    ]),
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default';
    },
    sorterValue: {
      get() {
        const sorter = (this.sortBy || [])[0];
        const key = sorter ? sorter.order.toLowerCase() : undefined;
        if (!key) return undefined;

        return {
          column: sorter.queryField,
          desc: key === 'desc',
          asc: key === 'asc'
        };
      },
      set(val) {
        this.sortBy[0] = {
          queryField: val.column,
          order: val.asc ? 'Asc' : 'Desc',
        };
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('entrants', ['handleGetEntrantsByQuery']),
    async initialize() {
      this.query = this.entityType
        ? [
            {
              queryField: 'entityType',
              queryValues: [this.entityType]
            },
            {
              queryField: 'memberId',
              queryValues: [this.$route.params.id]
            }
        ]
        : [
            {
              queryField: 'participationId',
              queryValues: [this.entityId]
            }
        ];

      this.entrantsData = await this.handleGetEntrantsByQuery({
        queryRequest: {
          must: this.query,
          sortBy: this.sortBy,
          skip: 0,
          limit: this.itemsPerPage
        }
      });

      this.ready = true;
    },
    async itemsPerPageSelect(val) {
      this.itemsPerPage = val;
      this.page = 1;
      this.entrantsData = await this.handleGetEntrantsByQuery({
        queryRequest: {
          must: this.query,
          sortBy: this.sortBy,
          skip: (this.page - 1) * this.itemsPerPage,
          limit: this.itemsPerPage
        }
      });
    },
    async paginationChange(val) {
      this.page = val;
      this.entrantsData = await this.handleGetEntrantsByQuery({
        queryRequest: {
          must: this.query,
          sortBy: this.sortBy,
          skip: (this.page - 1) * this.itemsPerPage,
          limit: this.itemsPerPage
        }
      });
    },
    async searchData(value) {
      let multiFields = [];
      if (value.trim()) {
        multiFields.push({
          queryFields: this.searchableAllFields,
          queryValue: value
        });
      }
      this.entrantsData = await this.handleGetEntrantsByQuery({
        queryRequest: {
          multiFields: multiFields,
          sortBy: this.sortBy,
          skip: 0,
          limit: this.itemsPerPage
        }
      });
    },
    handleCopy(id) {
      navigator.clipboard.writeText(id);
    },
    async handleReload() {
      this.entrantsData = await this.handleGetEntrantsByQuery({
        queryRequest: {
          must: this.query,
          sortBy: this.sortBy,
          skip: 0,
          limit: this.itemsPerPage
        }
      });
    },
    async handleSortBy(e) {
      this.sortBy = [
        {
          queryField: e.column,
          order: e.asc ? 'Asc' : 'Desc',
        },
      ];
      this.page = 1;
      this.entrantsData = await this.handleGetEntrantsByQuery({
        queryRequest: {
          must: this.query,
          sortBy: this.sortBy,
          skip: 0,
          limit: this.itemsPerPage
        }
      });
    },
  }
};
</script>

<style scoped lang="scss">
.entrants {
  &--actions-header {
    display: flex;
    justify-content: space-between;
  }
}

.zq-card-row--copy-icon {
  margin-left: 0.5rem;
  cursor: copy;
}
</style>
