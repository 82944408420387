<template>
  <CRow class="schedule-detail">
    <CCol col="12">
      <CCard class="zq--wizard-card">
        <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
          <div class="d-flex justify-content-between">
            <strong class="title">{{ title }}</strong>
            <CLink class="card-header-action btn-minimize">
              <ClCardArrow :is-collapsed="basicIsCollapsed" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="basicIsCollapsed" :duration="400">
          <CCardBody>
            <div v-if="creationType !== 'contest'">
              <!--      Auto Start        -->
              <ClCardRow :label="texts.summary.scheduling.autoStartLabel" :content="`${data.autoStart}`" />
              <!--      Auto Stop        -->
              <ClCardRow :label="texts.summary.scheduling.autoStopLabel" :content="`${data.autoStop}`" />
              <!--       Container duration        -->
              <ClCardRow :label="texts.summary.scheduling.durationLabel" :content="durationTime" />
              <!--       Number of Rounds        -->
              <ClCardRow v-if="creationType === 'advanced'" :label="texts.summary.scheduling.numberRoundsLabel" :content="data.numberOfRounds" />
            </div>
            <!--       Start Date        -->
            <ClCardRow
              v-if="data.scheduledStartDate"
              :label="texts.summary.scheduling.fromLabel"
              :content="dateFormate(data.scheduledStartDate)"
            />
            <!--       End Date        -->
            <ClCardRow
              v-if="data.scheduledEndDate"
              :label="texts.summary.scheduling.untilLabel"
              :content="dateFormate(data.scheduledEndDate)"
            />
            <!--   Scoring         -->
            <div v-if="creationType !== 'advanced'">
              <ClCardRow :label="texts.summary.scheduling.scoringLabel" :content="getStrategyName(data.strategies.strategyType)" />
              <ClCardRow :label="'Value'" :content="getStrategyValue(data.strategies.scoringStrategy)" v-if="data.strategies.strategyType && data.strategies.strategyType !== 'TotalCumulative'"/>
              <!--      Score ranking order descending        -->
              <ClCardRow :label="texts.summary.scheduling.rankingLabel" :content="`${data.scoreDesc}`" />
              <!--     Time ranking order descending       -->
              <ClCardRow :label="texts.summary.scheduling.timeLabel" :content="`${data.timeDesc}`" />
              <!--     Rank order by scores first      -->
              <ClCardRow :label="texts.summary.scheduling.rankLabel" :content="`${data.scoreFirst}`" />
              <!--     Discard time when ranking    -->
              <ClCardRow :label="texts.summary.scheduling.discardTimeLabel" :content="`${data.ignoreTime}`" />
              <!--     Discard score when ranking   -->
              <ClCardRow :label="texts.summary.scheduling.discardScoreLabel" :content="`${data.ignoreScore}`" />
              <!--     Exclude If Goal Not Reached   -->
              <ClCardRow :label="texts.summary.scheduling.excludeIfGoalNotReachedLabel" :content="`${data.excludeIfGoalNotReached}`" />
              <!--      Round        -->
              <ClCardRow
                v-if="data.round"
                :label="texts.summary.scheduling.roundLabel"
                :content="`${data.round}`"
              />
              <!--    Entrants from contest     -->
              <ClCardRow :label="texts.summary.scheduling.entrantsFromContestLabel" v-if="data.entrantsFromContest">
                <template #content>
                  <ul class="zq--list">
                    <li v-for="(group, index) in data.entrantsFromContest"
                      :key="index" class="group px-2 py-1 mr-2">{{ group }}
                    </li>
                  </ul>
                </template>
              </ClCardRow>
            </div>
          </CCardBody>
        </CCollapse>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import {startCase, get} from 'lodash';
import {dateFormate} from '@/utils/dateFormate';
import {getDuration} from '@/utils/timeUtils';
import ClCardRow from "@/shared/UI/ClCardRow";
import ClCardArrow from "@/shared/UI/ClCardArrow";
import {competitionsTexts} from "@/config/pageTexts/competitions.json";

export default {
  components: {
    ClCardRow,
    ClCardArrow
  },
  props: {
    schedulingData: Object,
    isAdvanced: {
      type: Boolean,
      default: false
    },
    creationType: {
      type: String,
      default: 'simple'
    },
  },
  data() {
    return {
      texts: {
        ...competitionsTexts
      },
      basicIsCollapsed: false,
      data: {}
    }
  },
  created() {
    this.data = this.schedulingData;
    this.setRankingStrategyConstraints();
  },
  computed: {
    title() {
      if (this.creationType === 'contest') {
        return this.texts.summary.scheduling.scoringTitle;
      } else if (this.creationType === 'advanced') {
        return this.texts.summary.scheduling.schedulingTitle;
      }
      return this.texts.summary.scheduling.sSTitle;
    },
    durationTime() {
      return getDuration(this.data.scheduledStartDate, this.data.scheduledEndDate);
    },
    scoringStrategyValue() {
      const strategy = get(this.data, 'strategies.scoringStrategy');
      const label = Object.keys(strategy).filter(item => strategy[item])[0];
      const value = strategy[label];
      return {label, value};
    }
  },
  methods: {
    dateFormate(val) {
      return val ? dateFormate(val) : ''
    },
    setRankingStrategyConstraints() {
      this.data.strategies.rankingStrategy.constraints.map(item => this.schedulingData[item] = true);
    },
    getStrategyName(strategyType) {
      switch (strategyType) {
        case 'TotalCumulative': {
          return 'Cumulative Strategy';
        }
        case 'SumBest': {
          return 'Highest Only';
        }
        case 'LimitedTo': {
          return 'Sum over Count';
        }
        case 'FirstTo': {
          return 'Sum over Count - including a goal in time marker';
        }
        default : {
          return '';
        }
      }
    },
    getStrategyValue(scoringStrategy) {
      const scoringValue = (Object.values(scoringStrategy).filter(item => item));
      return scoringValue[0] ? scoringValue[0] : '';
    }
  },
  watch: {
    schedulingData: {
      deep: true,
      handler(val) {
        this.data = val;
      }
    }
  }
}
</script>

<style lang="scss">
.schedule-detail {
  .every-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    li {
      background: #f1f1f1 none repeat scroll 0 0;
      border: 1px solid #f1f1f1;
      cursor: pointer;
      float: left;
      font-size: 16px;
      line-height: 30px;
      margin: 0 2px 2px 0;
      min-width: 45px;
      padding: 0 3px;
      text-align: center;
    }
  }
  @media screen and (max-width: 576px) {
    .overview-content {
      text-align: center;
    }
  }
}
</style>